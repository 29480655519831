import { supportEmailAddress } from '@/utils/config';

/* eslint-disable max-lines */
const translation = {
  noRecommendedImprovements: 'We have no recommendations for improvements yet.',
  recommendedSustainabilityActivities: 'Recommended improvements',
  gCo2PerPerDKKSaved: 'g CO₂/year/DKK',
  tonCo2PerYear: 'ton CO₂/year',
  co2Saving: 'Save',
  createdAt: 'Created at',
  lastSignIn: 'Last signed in',
  signedUp: 'User created',
  improvementSuggestionNotice: 'Check it out! You can now see suggestions for energy improvements in the app. 🎉🎉👏👏',
  recommended: 'Recommended',
  profitable: 'Profitable',
  externalLink: 'Link to energy label',
  improvementLegendTooltip:
    'Improvements that, among other things, can help with energy consumption. This can also include improvements from energy certification reports.',
  noBuildingsFound: 'No buildings found',
  createNewPassword: 'Create new password',
  resetPasswordEmailSent: 'Password recovery email sent. Check your inbox.',
  resetPasswordDescription:
    'Input your email below. If the email is associated with a user, we will send an email with a password reset link. Follow the link to create a new password.',
  resetPassword: 'Reset password',
  sendResetPasswordRequest: 'Request password reset',
  forgotPassword: 'Forgot password?',
  noImagesTaken: 'No photos have been taken of the property yet.',
  photos: 'Photos',
  defaultPlanLength: 'Default length of maintenance plan (year)',
  deletePlan: 'Delete plan',
  planDeleted: 'The plan has been deleted',
  failedToDeletePlan: 'Failed to delete plan',
  deletingPlan: 'Deleting plan',
  copyPlan: 'Duplicate plan',
  areYouSureYouWantToDeletePlanDescription:
    'Are you sure you want to delete the plan and all its activities? This cannot be undone.',
  areYouSureYouWantToDeletePlan: 'Are you sure you want to delete the plan?',
  startYear: 'Start year',
  planLengthYears: 'Length of the plan in years',
  around: 'Around',
  BBRUpdatedAt: 'BBR last updated at',
  predictionsUpdatedAt: 'Predictions last updated at',
  now: 'Now',
  daysAgo: 'Days ago',
  CRONMonitor: 'CRON monitor',
  noCRONDataPoints: 'Could not find any data points for CRON job.',
  failedToDownloadExcelSheet:
    'Failed to download the data for this property. Try again later. If the problem persists, please reach out to support.',
  preventiveActivities: 'Preventive activities',
  restorativeActivities: 'Restorative activities',
  remedialActivities: 'Remedial activities',
  improvementActivities: 'Improvement activities',
  predictedPreventiveActivities: 'Predicted preventive activities',
  predictedRestorativeActivities: 'Predicted restorative activities',
  amountType: 'Amount type',
  amount: 'Amount',
  installationYear: 'Installation year',
  material: 'Material',
  FVKCode: 'FVK code',
  buildingBBRUuid: 'Building BBR UUID',
  downloadExcelExport: 'Download all data for this property',
  noRegistrationsStatus: 'No registrations have been created for this property',
  registrationIsFinsihed: 'Registrations on the property are finished',
  registrationInProgress: 'Registrations on the property are ongoing',
  statusForregistrationTooltip: 'Status of the registrations on the property',
  updating: 'Updating',
  unknown: 'unknown',
  confidenceScoreRange: 'Confidence score range',
  noSuggestionsForSearch: 'There are no suggestions matching you search criteria.',
  status: 'Status',
  notStarted: 'Not started',
  close: 'Close',
  deletingProperty: 'Deleting property',
  deleteThisPropertyDescription:
    'If you delete this property, all completed registrations and maintenance plans will also be deleted. This cannot be undone. Are you sure you want to continue?',
  noRegistrationsForThisSearch: 'No registrations found for this search.',
  reset: 'Reset',
  propertyFilterTitle: 'Filter properties',
  confirmSubmitRegistration: 'Are you sure you want to submit the registration',
  confirmSubmitRegistrationDescription:
    'You can no longer change your mind once you have submitted the registration. The registration cannot be deted either. Are you sure you want to submit it? Alternatively you can save it for later, if you think you will be making changes to it again.',
  propertyRenamed: 'Property was renamed',
  rename: 'Rename',
  renameProperty: 'Rename property',
  inspected: 'Inspected',
  registration: 'Registration',
  report: 'Report',
  uploadTemplate: 'Upload property import template',
  done: 'Done',
  inProgress: 'In progress',
  finishedAt: 'Finished',
  rowsSelected: 'rows selected',
  rowSelected: 'row selected',
  reminderSent: 'Reminder sent',
  emailHasDeadline: 'The registration has a deadline:',
  sendReminder: 'Send reminder',
  confirmReminder: 'Are you sure you want to send a reminder?',
  confirmReminderDescription:
    'Are you sure you want to send an email reminder to the user? The reminder will inform the user that they still need to complete the registration assigned to them.',

  registrationTitle: 'Registration title',
  deadline: 'Deadline',
  deleteThisRegistrationDescription:
    'Are you sure you want to delete this registration? It will also delete images and activities collected in connection with the registration.',
  estimatingCosts: 'Generating cost predictions',
  calculatingPredictionScores: 'Calculating prediction scores',
  structuringPredictions: 'Structuring predictions',
  of: 'of',
  startingDataCollection: 'Starting collection of data',
  gatheringCredentials: 'Gathering credentials and meta data',
  structuringInformation: 'Structuring property information',
  fetchingBBRData: 'Fetching public property data',
  structuringBBRData: 'Structuring public property data',
  generatingPredictions: 'Generating condition predictions for property',
  assessingPredictions: 'Assessing predictions',
  loadingPropertyPage: 'Loading property page',
  suggestionsInfo:
    'This is a list of recommended registrations. If a property has an overall security score below 80%, we recommend creating a registration to increase the security score. The suggested registration is pre-filled with the building components that constitute the minimum required amount to raise the overall security score to over 80% for the property. The list is sorted by properties with the lowest security score. If a property has open registrations, we do not suggest creating additional ones.',
  noSuggestions: 'There are no suggestions',
  buildingElements: 'building elements',
  outOf: 'out of',
  registrationSuggestions: 'Suggested registrations',
  informationAbout: 'Information about',
  energyLabelsForBuildings: 'Energy labels for your buildings',
  noEnergyLabelInfo: 'No energy label information available',
  noOutStandingActivities: 'There are no more outstanding activities',
  lastUpdatedAt: 'Last updated at',
  cloudVersion: 'Cloud version',
  localVersion: 'Local version',
  chooseRegistration: 'Choose registration',
  choose: 'Choose',
  chooseRegistrationVersionTitle: 'Choose which registration you want to continue with',
  chooseRegistrationVersionText:
    'It looks like the registration saved on your device is different from the registration we have on file. Which registration do you want to proceed with? You cannot undo this choice. We will delete the other registration.',

  saved: 'Saved',
  syncing: 'Synchronizing',
  help: 'Help',
  ifCustomerIsInUseThisMightHaveImpact:
    'If you make changes to this list, it might have unforseen consequences if the system is already in use by this customer.',
  buildingElementsInUse: 'Building elements used by this customer',
  customerUpdated: 'The customer has been updated',
  customerDeleted: 'The customer has been deleted',
  editCustomerTitle: 'Edit customer',
  deleteCustomerDescription:
    'Are you sure you want to delete this customer? This will delete both users, registrations and maintenance plans. The action cannot be undone.',
  deleteCustomer: 'Delete  customer',
  downloadPropertyImportTemplate: 'Download property import template',
  propertiesCreated: 'The properties have been imported and created.',
  downloadPropertyList: 'Download excel export of properties',
  m2: 'm2',
  lastBBRUpdate: 'Last BBR update',
  lastPublicPropertyInfoUpdate: 'Last property update',
  lastPredictions: 'Last predictions',
  updatePredictions: 'Update predictions',
  needHelp: 'Do you need help?',
  needHelpDescription: 'If you have questions, problems or a need for help, reach out to us at:',
  deleteUserDescription:
    'Are you sure you want to delete this user? This will also delete all the registrations carried out by the user. This cannot be undone!',
  upload: 'Upload',
  failedToFindAddress: 'Failed to find address',
  failedToFindBFENumber: 'Failed to find BFE number for address',
  failedToFindOrganizationLevel: 'Failed to find organization levels',
  failedToFindParentOrganization: 'Failed to find parent organization',
  generatingReport: 'Generating report',
  updatingRegistrationList: 'Updating the registration list',
  registrationCreated: 'The registration has been created',
  confirmSignUp: 'Confirm sign up',
  propertyDeleted: 'The property has been deleted',
  deleteThisProperty: 'Are you sure you want to delete this property?',
  registrationDeleted: 'The registration has been deleted',
  deleteThisRegistration: 'Are you sure you want to delete this registration?',
  customerAdded: 'The organization has been added',
  noOrganizationsFound: 'No organizations found',
  addOrganization: 'Add organization',
  emailLinkExpired: 'Your email link has expired. Contact ' + supportEmailAddress + ' to get a new one.',
  passwordSet: 'Your password was created succesfully!',
  somthingWentWrong: 'Something went wrong. Try again.',
  submit: 'Godkend',
  repeat: 'Repeat',
  createPassword: 'Create password',
  userInvited: 'User invited',
  userSaved: 'Changes saved',
  selectRole: 'Select role',
  selectCustomer: 'Select customer',
  invite: 'Invite',
  inviteUser: 'Invite user',
  editUser: 'Edit user',
  deleteUser: 'Delete user',
  customer: 'Customer',
  type: 'Type',
  noUsersFound: "We couldn't find any users",
  admin: 'Admin',
  number: 'Number',
  thousandCrowns: 'Whole thousand DKK',
  totalYear: 'Total {{maintenance_plan_length}} years',
  property: 'Property',
  buildingPart: 'Building part',
  rawPredictions: 'Raw predictions',
  rawRegistrations: 'Raw registrations',
  conditionOverview: 'Condition overview',
  multipleProperties: 'Multiple properties',
  defect: 'defect',
  worn: 'worn out',
  used: 'used',
  good: 'good',
  new: 'new',
  years: 'years',
  checklist: 'Checklist',
  description: 'Description',
  noRegistrations: 'Add a registration to get started',
  home: 'Home',
  data: 'Data',
  properties: 'Properties',
  users: 'Users',
  registrations: 'Registrations',
  map: 'Map',
  profile: 'Profile',
  logOut: 'Log out',
  logIn: 'Log in',
  logInWithPassword: 'Log in with password',
  logInWithSSO: 'Log in with SSO',
  or: 'Or',
  setUpSSO: 'Contact us to set up SSO for your organization if you do not already have it at: ',
  danish: 'Danish',
  english: 'English',
  noPropertiesFound: 'No properties found',
  propertyNumber: 'Property number',
  name: 'Name',
  rowsPerPage: 'Rows per page:',
  propertiesSearchPlaceholder: 'Address, property No., etc...',
  search: 'Search',
  password: 'Password',
  buildings: 'Buildings',
  buildingNumber_one: 'Building number',
  buildingNumber_other: 'Building numbers',
  address: 'Address',
  application: 'Application',
  condition: 'Condition',
  bfeNumber: 'BFE number',
  department: 'Department',
  links: 'Links',
  caretaker: 'Caretaker',
  registrationsForProperty: 'Registrations',
  add: 'Add',
  start: 'Start',
  overallCondition: 'Overall condition',
  overallConditionOfProperty: 'Overall condition of property',
  maintenancePlan: 'Maintenance plan',
  maintenanceNextXYears: 'Maintenance for {{start_year}} - {{end_year}}',
  confidence: 'Confidence',
  overallConfidence: 'Overall confidence',
  seeMore: 'See more',
  createRegistration: 'Create registration',
  cancel: 'Cancel',
  create: 'Create',
  createAndClose: 'Create & close',
  createAndContinue: 'Continue with registration',
  buildingPartsToRegister: 'Building parts to register',
  checkAll: 'Check all',
  uncheckAll: 'Uncheck all',
  building: 'Building',
  buildingsToRegister: 'Choose buildings to register',
  message: 'Write a message to the recipient of the registration',
  writeAMessage: 'Write a message',
  to: 'to',
  whoShouldRegister: 'Who should register?',
  existingUser: 'Existing user',
  waiting: 'Waiting',
  wantToRegisterOnMoreBuildings: 'Want to register on more buildings at a time?',
  startRegistration: 'Start registration',
  finish: 'Finish',
  saveForLater: 'Save for later',
  overallConditionPart: 'Overall condition on',
  registerLifespan: 'Register lifespan',
  addActivity: 'Add activity',
  addPicture: 'Add picture',
  addComment: 'Add comment',
  editComment: 'Edit comment',
  year_one: 'Year',
  year_other: 'Years',
  continue: 'Continue',
  doesntExist: "Doesn't exist",
  notInspected: 'Not inspected',
  lastUpdated: 'Last updated',
  constructive: 'Constructive',
  preventive: 'Preventive',
  improvement: 'Improvement',
  remedial: 'Remedial',
  price: 'Price',
  priceInThousands: 'Price in thousands',
  pleaseSelectToSeeCondition: 'Please select one or more buildings or properties to view condition predictions',
  pleaseSelectToSeePlan: 'Please select one or more buildings or properties to view the predicted maintenance plan',
  comment: 'Comment',
  frequence: 'Frequence',
  everyXYear: 'Every {{year}}. year',
  noFrequence: 'No frequence',
  distributeOverYears: 'Distribute over a range of years',
  endYear: 'End year',
  totalPrice: 'Total price',
  pricePerYear: 'Price per year',
  numberOfYears: 'Number of years',
  thousandSeparator: ',',
  decimalSeparator: '.',
  save: 'Save',
  startingYear: 'Starting in year',
  delete: 'Delete',
  confirm: 'Confirm',
  deleteThisActivity: 'Want to delete this activity?',
  writeAComment: 'Write a comment',
  viewingDataForProperties: "Properties you're viewing data for",
  viewingDataForBuildings: "Buildings you're viewing data for",
  lifespan: 'Lifespan',
  constructionYear: 'Construction year',
  postalCode: 'Postal code',
  organization: 'Organization',
  organizations: 'Organizations',
  filter: 'Filter',
  filterPropertiesAndBuildings: 'Filter properties and buildings',
  resetFilters: 'Reset filters',
  languages: {
    da: 'Danish',
    en: 'English',
  },
  themes: {
    light: 'Light',
    dark: 'Dark',
  },
  language: 'Language',
  theme: 'Theme',
  profileNotFound: 'Profile not found',
  phoneNumber: 'Phone number',
  email: 'Email',
  code: 'Code',
  preferences: 'Preferences',
  userType: 'User type',
  emailTranslations: {
    dear: 'Dear',
    youHaveBeenInvited: 'You have been invited to review',
    onBehalfOf: 'on behalf of',
    wantYouToLookOnFollowing: 'We would like you to look at the following building parts:',
    hasAddedMessage: 'has added the following message:',
    getOverview: 'You can start by getting an overview of the property by clicking the button below:',
    getOverviewButton: 'Get overview',
    startRegistration: 'When you are at the property, you can start the registration by clicking the button below:',
    startRegistrationButton: 'Start registration',
    anyQuestions: 'If you have any questions, please feel free to reply to this email.',
    bestRegards: 'Best regards',
  },
  title: 'Title',
  created: 'Created',
  allParts: 'All building parts',
  addBuildingPart: 'Add building part',
  back: 'Back',
  undo: 'Undo',
  markedAsDoestExist: 'Marked as "Does not exist"',
  markedAsNotInspected: 'Marked as "Not inspected"',
  remainingLifespan: 'Remaining lifespan',
  enteredData: 'You have entered data that will be lost if you continue',
  confirmNotInspected: 'Are you sure you want to mark this as "Not inspected"?',
  confirmDoesntExist: 'Are you sure you want to mark this as "Does not exist"?',
  images: 'Images',
  required: 'required',
  deleteImage: 'Are you sure you want to delete this image?',
  activities: 'Activities',
  addProperty: 'Add property',
  searchProperties: 'Search properties',
  searchPropertiesPlaceholder: 'BFE number, address, organization...',
  startTypingToSearch: 'Start typing to search',
  noAddressesFound: 'No addresses found',
  goToProperty: 'Go to property',
  parentOrganization: 'Parent organization',
  company: 'Company',
  outside: 'Outside',
  inside: 'Inside',
  download: 'Download',
  downloadMaintenancePlan: 'Maintenance plan',
  total: 'Total',
  total2: 'Total',
  openAll: 'Open all',
  closeAll: 'Close all',
  planMaintenance: 'Plan Maintenance',
  estimatedMaintenance: 'Estimated Maintenance',
  suggestedActivities: 'Suggested activities',
  chooseOnlyOne: 'Choose only one',
  comparison: 'Comparison',
  editActivity: 'Edit activity',
  planned: 'Planned',
  collision: 'Collision',
  postpone: 'Postpone',
  consolidate: 'Consolidate',
  constructionSite: 'Construction site',
  tipsAndTricks: 'Tips & Tricks',
  tipsDistrictHeating: 'Check district heating rollout plan',
  tipsDistrictHeatingDialogContent:
    "Have you checked when district heating gets rolled out in the area?<br/>Keep an eye on <a rel='noopener noreferrer' target='_blank' href='https://kort.plandata.dk/'>Plan- og Landdistriktsstyrelsen's website</a> for the latest information.",
  tipsConsolidateScaffolding: 'Move construction to {{year}} to save on construction site fees',
  tipsConsolidateScaffoldingDialog1: 'You have a number of constructive activities spread over a long period of time.',
  tipsConsolidateScaffoldingDialog2:
    'Construction on these building parts generally require setting up scaffolding etc., and you could potentially save money by moving these activities closer together. We recommend moving them to {{year}}, and adding neccesary preventive maintenance activities where needed.',
  tipsPostponeTerrainBlocking: 'Postpone activity to avoid damaging terrain',
  tipsPostponeterrainBlockingDialog1: 'Your plan contains renovations of terrain(',
  tipsPostponeterrainBlockingDialog2:
    ', {{activityYear}}), followed by activities that may require a construction site.',
  tipsPostponeterrainBlockingDialog3: 'Consider moving them to after {{year}}.',
  unplannedActivities: 'Unplanned activities',
  unplannedActivity: 'Unplanned activity',
  unplannedActivityLegendTooltip: 'Your unplanned activities',
  predictedActivity: 'Predicted activity',
  predictedActivityLegendTooltip: 'Predicted maintenance cost from the AI',
  collisionLegendTooltip: 'Difference between your planned activity and the AI predicted maintenance cost',
  tipsAndTricksLegendTooltip: 'Recommended things to keep in mind when planning activities',
  preventiveMaintenance: 'Preventive maintenance',
  constructiveMaintenance: 'Constructive maintenance',
  differenceBetweenPlannedAndPredicted: 'Difference between planned and predicted',
  thousandShorthand: 'k',
  overMaintenanceBy: 'Over-maintenance by',
  underMaintenanceBy: 'Under-maintenance by',
  improvements: 'Improvements',
  creatingActivity: 'Creating activity',
  activityCreated: 'Activity created',
  plannedMaintenance: 'Planned maintenance',
  createMaintenancePlan: 'Create new maintenance plan',
  duplicateMaintenancePlan: 'Duplicate plan',
  dontDuplicate: "Don't duplicate",
  maintenancePlanName: 'Maintenance plan name',
  maintenancePlanStatus: 'Status',
  updateStatus: 'Update status',
  createNew: 'Create new',
  noPlansFound: 'No plans found. Create a plan to get started.',
  open: 'Open',
  approved: 'Approved',
  sentToExecution: 'Sent to execution',
  reopen: 'Reopen',
  approve: 'Approve',
  sendToExecution: 'Send to execution',
  confirmSendToExecution:
    'Are you sure you want to send to execution? This action annot be undone. Only one plan per year may be sent to execution.',
  maintenancePlanNotFoundError: 'Maintenance plan not found',
  maintenancePlanStatusUpdated: 'Status updated',
  maintenancePlanNameEmpty: 'Name is required',
  maintenancePlanUnplannedActivitiesRemainingError:
    "Can't approve or send plans to execution with unplanned activities",
  duplicateStatusApprovedError: 'Only one plan per year can have status approved',
  duplicateStatusSentToExecutionError: 'Only one plan per year can have status sent to execution',
  notFoundPageDescription: "Oops! We couldn't find the page you were looking for.",
} as const;

export default translation;
